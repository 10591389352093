<template>
  <div class="view view--light" ref="view" v-resize="onResize">
    <v-row class="mt-1">
      <v-col :cols="12" class="text-right">
        <v-btn @click="toggleAside({ isClipped: true, isTemporary: false })" color="primary" text>
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-col>
    </v-row>

    <div v-if="customerAircraft" class="mt-3 row--table-container">
      <v-card elevation="1" v-if="filters.displayMode === 'table'">
        <v-row>
          <v-col :cols="12" class="py-0">
            <v-skeleton-loader type="table-thead,table-tbody" v-if="isLoading"></v-skeleton-loader>
            <TableDocuments
              :items="filteredFiles"
              :columns="filters.columns.length ? filters.columns : tableColumns"
              :height="tableHeight"
              class="table__docs-overview"
              @resize="onResize"
              v-else
            />
          </v-col>
        </v-row>
      </v-card>

      <v-row v-if="filters.displayMode === 'timeline'">
        <v-col :cols="12" class="py-0">
          <v-skeleton-loader type="table-thead,table-tbody" v-if="isLoading"></v-skeleton-loader>
          <TimelineDocuments :items="filteredFiles" class="table__docs-overview" v-else />
        </v-col>
      </v-row>
    </div>

    <v-row class="mt-3" v-if="!customerAircraft">
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <!-- Aside -->
    <v-navigation-drawer
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      v-model="aside.isVisible"
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn @click="aside.isVisible = false" icon depressed text> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
      <FilterBarDocuments
        :filterOptions="filterOptions"
        :tableColumns="tableColumns"
        :selectedFilterOptions="filters"
        :selectedColumns="filters.columns"
        @change="onFilterChange"
        class="mt-3 px-3 is-inside-nav-drawer"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import * as FileService from '@/services/file';
import tableMixin from '@/shared/mixins/table';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import FilterBarDocuments from '@/components/FilterBar/FilterBarDocuments';
import TableDocuments from '@/components/Table/TableDocuments';
import TimelineDocuments from '@/components/Timeline/TimelineDocuments';

export default {
  name: 'DocumentsIndex',

  mixins: [tableMixin],

  components: {
    AlertNoAircraft,
    FilterBarDocuments,
    TableDocuments,
    TimelineDocuments
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      currentUser: `${USER_MODULE}/currentUser`,
      tags: `${TAGS_MODULE}/all`
    }),

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    customerAircraftEasaTypeId() {
      return get(this.customerAircraft, 'aircraft.easa_types.id');
    },

    filterOptions() {
      const tags = this.tags.slice();
      tags.map(tag => {
        tag.disabled = this.disabledTagValues.includes(tag.value);
        return tag;
      });

      const result = {
        tags: [...tags],
        tagsDocTypes: [], //[...this.tagsDocTypes],
        tagsReportTypes: [] //[...this.tagsReportTypes]
      };
      return result;
    },

    filteredFiles() {
      let result = this.files.slice();
      const { query = '' } = this.filters;

      // search query for props: [title, description]
      if (query && query.length) {
        result = result.filter(file => {
          const title = file.title.toLowerCase();
          const description = file.description ? file.description.toLowerCase() : '';

          return title.toLowerCase().includes(query.toLowerCase()) || description.toLowerCase().includes(query.toLowerCase());
        });
      }

      return result;
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    tagsDocTypes() {
      const values = ['aircraft-maintenance-program', 'airworthiness-inspection', 'insurance-certificate', 'certificate-of-airworthiness'];
      const result = this.tags.filter(tag => {
        return values.includes(tag.value);
      });

      return result;
    },

    tagsReportTypes() {
      const values = [
        'work-report',
        'report-of-findings',
        'weight-and-balance-report',
        'flight-report',
        'compensation-reports',
        'adjustment-reports'
      ];
      const result = this.tags.filter(tag => {
        return values.includes(tag.value);
      });

      return result;
    }
  },

  data() {
    return {
      aside: {
        component: '',
        headline: this.$t('Filters'),
        isClipped: false,
        isTemporary: true,
        isVisible: false
      },
      disabledTagValues: ['airworthiness-directive', 'lufttuechtigkeitsanweisung', 'technische-mitteilung', 'manual'],
      allowedTagValues: ['document'],
      files: [],
      filters: {
        columns: [],
        displayMode: 'table',
        docType: {},
        query: '',
        tags: []
      },
      isLoading: false,
      tableColumns: [
        {
          text: 'date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Revision',
          value: 'revision'
        },
        {
          text: 'Company',
          value: 'company'
        },
        {
          text: 'Authorities',
          value: 'authorities'
        },
        {
          text: 'Tags',
          value: 'tags'
        },
        {
          text: 'MaintenanceTypes',
          value: 'maintenanceTypes'
        }
      ],
      tableHeight: ''
    };
  },

  mounted() {
    // Pre-fill filters
    this.filters.columns.push(this.tableColumns[0]);
    this.filters.columns.push(this.tableColumns[1]);
    this.filters.columns.push(this.tableColumns[2]);

    if (this.customerAircraftId) {
      this.handleRouteChange();
    }

    this.onResize();
  },

  methods: {
    handleRouteChange() {
      const { tags } = this.$route.query;

      const queryTags = [];
      if (tags && typeof tags === 'string') {
        queryTags.push(tags);
      } else if (tags) {
        queryTags.push(...tags);
      }
      this.filters.tags = queryTags.slice();
      this.onFilterChange(this.filters);
    },

    onFilterChange(values) {
      this.filters = Object.assign({}, values);
      this.requestFiles();
    },

    async onResize() {
      await this.$nextTick(() => {
        this.setTableHeight(this.$refs['view'], 550, document.querySelector('.table__docs-overview table'));
      });
    },

    async requestFiles() {
      /**
       * @todo Bug in directus wenn nested o2m gefilter werden
       * https://github.com/directus/directus/issues/11655
       */
      const filter = {
        _and: []
      };

      // Respect selected aircraft
      if (this.customerAircraftId && !this.filters.tags.includes('type-certificate-data-sheet')) {
        filter['_and'].push({
          cust_aircraft: {
            cust_aircrafts_id: {
              id: {
                _eq: this.customerAircraftId
              }
            }
          }
        });
      }

      // Only for tag 'type-certificate-data-sheet'
      if (this.filters.tags.includes('type-certificate-data-sheet')) {
        filter['_and'].push({
          easa_types: {
            easa_types_id: {
              id: {
                _eq: this.customerAircraftEasaTypeId
              }
            }
          }
        });
      }

      // Filter by tag ids
      if (this.filters.tags.length) {
        this.filters.tags.forEach(id => {
          filter['_and'].push({
            base_tags: {
              base_tags_id: {
                value: {
                  _eq: id
                }
              }
            }
          });
        });
      }

      try {
        this.isLoading = true;
        const { data = [] } = await FileService.getFiles(filter);

        // @todo Remove if nested filter for o2m works in directus
        const files = data.filter(file => {
          let result = true;
          file.base_tags.forEach(relation => {
            if (this.disabledTagValues.includes(relation.base_tags_id.value)) {
              result = false;
            }
          });
          return result;
        });
        this.files = FileService.transformRootLeafsTwoLevel(files);

        setTimeout(() => {
          this.onResize();
        }, 100);
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    toggleAside(params = {}) {
      // reset aside settings
      const { component = '', isClipped = false, isTemporary = true } = params;
      this.aside.component = component;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;

      if (component === 'tableColumns') {
        this.aside.component = component;
        this.aside.isClipped = true;
        this.aside.isTemporary = false;
        this.aside.headline = this.$t('selectColumns');
      }

      this.aside.isVisible = !this.aside.isVisible;
    }
  },

  watch: {
    $route() {
      this.handleRouteChange();
    },

    customerAircraftId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleRouteChange();
      }
    }

    // 'filters.docType'(newDocType, oldDocType) {
    //   const newValue = get(newDocType, 'value');
    //   const oldValue = get(oldDocType, 'value');

    //   const query = get(this.$route, 'query', {});
    //   const { docType } = query;

    //   if (newValue === oldValue || docType === newValue) {
    //     return;
    //   }

    //   const queryEntries = Object.entries(query);
    //   const queryEntriesFiltered = queryEntries.filter(item => {
    //     return item[0] !== 'docType';
    //   });
    //   const newQuery = Object.fromEntries(queryEntriesFiltered);

    //   const route = {
    //     name: DOCUMENT,
    //     query: Object.assign({}, newQuery)
    //   };

    //   if (newValue) {
    //     route.query.docType = newValue;
    //   }

    //   this.$router.replace(route);
    //   console.log(query, newQuery);
    // },

    // 'filters.displayMode'(newValue, oldValue) {
    //   if (newValue === oldValue) {
    //     return;
    //   }

    //   const query = get(this.$route, 'query', {});
    //   const queryEntries = Object.entries(query);
    //   const queryEntriesFiltered = queryEntries.filter(item => {
    //     return item[0] !== 'displayMode';
    //   });
    //   const newQuery = Object.fromEntries(queryEntriesFiltered);

    //   const route = {
    //     name: DOCUMENT,
    //     query: Object.assign({}, newQuery)
    //   };

    //   if (newValue) {
    //     route.query.displayMode = newValue;
    //     // this.$router.replace(route);
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.view {
  overflow: auto;
}
</style>
