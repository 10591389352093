<template>
  <div class="filterbar--documents" :class="{ 'is-mdAndUp': $vuetify.breakpoint.mdAndUp }">
    <v-text-field
      type="text"
      :label="$t('label.searchInput')"
      :error="isSearchQueryValid"
      :error-messages="searchQueryMessage"
      v-model="searchQuery"
      clearable
      prepend-icon="mdi-magnify"
      class="filterbar__item"
      @input="onChangeFilter"
    >
    </v-text-field>

    <v-select
      v-if="false"
      :items="filterOptions.tagsDocTypes"
      :item-text="item => $t(item.name)"
      :disabled="!filterOptions.tagsDocTypes.length"
      :label="$t('label.selectDocumentType')"
      v-model="selectedDocType"
      prepend-icon="mdi-file-outline"
      clearable
      return-object
      class="filterbar__item"
      @change="onChangeFilter"
    >
      <template v-slot:selection="{ item }">
        <span>{{ $t(item.name) }}</span>
      </template>
    </v-select>

    <v-select
      v-if="false"
      :items="filterOptions.tagsReportTypes"
      :item-text="item => $t(item.name)"
      :disabled="!filterOptions.tagsReportTypes.length"
      :label="$t('label.selectReportType')"
      v-model="selectedDocType"
      prepend-icon="mdi-file-outline"
      clearable
      return-object
      class="filterbar__item"
      @change="onChangeFilter"
    >
      <template v-slot:selection="{ item }">
        <span>{{ $t(item.name) }}</span>
      </template>
    </v-select>

    <v-autocomplete
      :items="filterOptions.tags"
      :item-text="
        item => {
          return item.abbr ? `${$t(item.name)} (${item.abbr})` : $t(item.name);
        }
      "
      :item-value="item => $t(item.value)"
      :item-disabled="item => (item.disabled ? item.disabled : false)"
      :disabled="!filterOptions.tags.length"
      v-model="selectedTags"
      prepend-icon="mdi-tag-multiple-outline"
      multiple
      clearable
      :label="$t('label.selectTags')"
      class="filterbar__item"
      @change="onChangeFilter"
    >
      <template v-slot:selection="{ item }">
        <v-chip small color="accent">
          <span v-if="item.abbr">{{ $t(item.abbr) }} </span>
          <span v-else>{{ $t(item.name) }}</span>
        </v-chip>
      </template>
    </v-autocomplete>

    <div class="filterbar__item mt-7">
      <h3 class="h2">{{ $t('Settings') }}</h3>
      <v-divider></v-divider>
    </div>

    <v-select
      :items="displayModes"
      :item-text="item => $t(item.text)"
      :item-value="item => $t(item.value)"
      :label="$t('label.selectDisplayMode')"
      :ripple="false"
      v-model="selectedDisplayMode"
      class="filterbar__item mt-6"
      @change="onChangeFilter"
    >
    </v-select>

    <v-combobox
      v-model="selectedTableColumns"
      :items="tableColumns"
      :item-text="item => $t(item.text)"
      :label="$t('label.selectedTableColumns')"
      :disabled="!tableColumns.length || selectedDisplayMode !== 'table'"
      multiple
      clearable
      class="filterbar__item "
      @change="onChangeFilter"
    >
      <template v-slot:selection="{ item }">
        <v-chip small color="accent">
          <span>{{ $t(item.text) }}</span>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'FilterBarDocuments',

  props: {
    filterOptions: {
      type: Object,
      required: false,
      default() {
        return {
          tags: [],
          tagsDocTypes: [],
          tagsReportTypes: []
        };
      }
    },

    selectedColumns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    selectedFilterOptions: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },

    tableColumns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },

  computed: {
    allFilters() {
      return {
        columns: this.selectedTableColumns,
        // docType: this.selectedDocType,
        displayMode: this.selectedDisplayMode,
        query: this.searchQuery,
        tags: this.selectedTags
      };
    },

    isSearchQueryValid() {
      let result = false;
      if (this.searchQuery && this.searchQuery.length && this.searchQuery.length <= this.minSearchQueryLength) {
        result = true;
      }
      return result;
    },

    searchQueryMessage() {
      let result = '';
      if (this.searchQuery && this.searchQuery.length && this.searchQuery.length <= this.minSearchQueryLength) {
        result = this.$t('error.searchQueryTooShort');
      }
      return result;
    }
  },

  data() {
    return {
      displayModes: [
        { text: 'mode.Table', value: 'table' },
        { text: 'mode.Timeline', value: 'timeline' }
      ],
      minSearchQueryLength: 0,
      searchQuery: '',
      selectedDisplayMode: 'table',
      selectedDocType: {},
      selectedTableColumns: [],
      selectedTags: []
    };
  },

  mounted() {
    this.preSelectColumns();
    this.preSelectTags();
    // this.onChangeFilter();
  },

  methods: {
    preSelectColumns() {
      if (this.selectedColumns.length) {
        this.selectedTableColumns = this.selectedColumns.slice();
      }
    },

    preSelectDocType() {
      const { docType } = this.selectedFilterOptions;
      if (docType) {
        this.selectedDocType = Object.assign({}, docType);
      }
    },

    preSelectTags() {
      const { tags = [] } = this.selectedFilterOptions;
      this.selectedTags = tags.slice();
    },

    onChangeFilter() {
      this.$emit('change', this.allFilters);
    }
  },

  watch: {
    // 'selectedFilterOptions.docType'() {
    //   this.preSelectDocType();
    // },

    'selectedFilterOptions.tags'() {
      this.preSelectTags();
    },

    selectedColumns() {
      this.preSelectColumns();
    }
  }
};
</script>

<style lang="scss" scoped>
.filterbar--documents {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.filterbar__item {
  @media screen and (min-width: $screen-xs) {
    margin: 0 0.625rem;
  }

  .is-inside-nav-drawer & {
    &:not(:first-child) {
      margin-top: 0.625rem;
    }
  }

  .is-inside-nav-drawer.is-mdAndUp & {
    width: 100%;
  }

  @media screen and (min-width: $screen-md) {
    width: calc((100% / 3) - 0.625rem * 2);
  }
}
</style>
