<template>
  <div>
    <v-card elevation="1" v-if="!sortedItems.length" class="pa-4">
      <v-alert text dense type="info" class="mb-0">
        {{ $t('hint.noData') }}
      </v-alert>
    </v-card>

    <div v-else>
      <v-row>
        <v-col :cols="3">
          <v-select
            :items="sortByItems"
            :item-text="item => $t(item.text)"
            :item-value="item => $t(item.value)"
            :label="$t('changeSortOrder')"
            :ripple="false"
            return-object
            v-model="sortedBy"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-timeline :reverse="isReverse" :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item v-for="item in sortedItems" :key="item.id">
          <span slot="opposite">
            <span v-if="item.published_at">
              {{ $d(new Date(item.published_at), 'short') }}
            </span>
            <span v-else-if="item.published_start">
              {{ $d(new Date(item.published_start), 'short') }}
            </span>
            <span v-else>
              {{ $d(new Date(item.uploaded_on), 'short') }}
            </span>
          </span>
          <v-card class="elevation-1">
            <v-card-title class="body-2">
              <a
                :href="getAssetUrl(item.id) + '?access_token=' + authToken"
                class="file-item__link d-flex flex-column align-items-center py-2"
                target="_blank"
                :title="$t('text.clickToOpenFile')"
              >
                <div class="d-flex">
                  <v-icon class="mr-1" small>{{ getIconByType(item.type) }}</v-icon>
                  {{ item.title }}
                </div>
              </a>
            </v-card-title>
            <v-card-text>
              <span v-if="item.description">{{ item.description }}</span>
              <div>
                <v-tooltip top v-for="tag in getRelationNames(item, 'base_tags', 'base_tags_id', true)" :key="tag.id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :ripple="false" small color="light-blue lighten-5" class="ma-1" v-bind="attrs" v-on="on">
                      <span v-if="tag.abbr">{{ tag.abbr }}</span>
                      <span v-else>{{ tag.name }}</span>
                    </v-chip>
                  </template>
                  <span>
                    {{ tag.name }}
                  </span>
                </v-tooltip>

                <v-chip
                  v-for="company in getRelationNames(item, 'companies', 'companies_id')"
                  :key="company.id"
                  :ripple="false"
                  small
                  color="blue lighten-4"
                  class="ma-1"
                >
                  {{ company.name }}
                </v-chip>

                <v-chip
                  v-for="authority in getRelationNames(item, 'authorities', 'authorities_id')"
                  :key="authority.id"
                  :ripple="false"
                  small
                  color="accent"
                  outlined
                  class="ma-1"
                >
                  {{ authority.name }}
                </v-chip>

                <v-tooltip top v-for="type in getRelationNames(item, 'bse_mnt_type', 'bse_mnt_types_id', true)" :key="type.id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip :ripple="false" small color="lime lighten-3" class="ma-1" v-bind="attrs" v-on="on">
                      {{ type.abbr }}
                    </v-chip>
                  </template>
                  <span>
                    {{ type.name }}
                  </span>
                </v-tooltip>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, orderBy } from 'lodash';
import { getAssetUrl, getRelationNames } from '@/services/utils';
// import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';

export default {
  name: 'TimelineDocuments',

  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: `${USER_MODULE}/currentUser`
    }),

    authToken() {
      return localStorage.getItem('auth_token') || '';
    },

    sortedItems() {
      let result = this.items.slice();

      const value = get(this.sortedBy, 'value', '').split('.')[0];
      const order = get(this.sortedBy, 'value', '').split('.')[1];

      if (value) {
        result = orderBy(
          result,
          [
            function(item) {
              if (item[`${value}`]) {
                return item[`${value}`];
              } else {
                return item['uploaded_on'];
              }
            }
          ],
          [order]
        );

        // result = result.sort(item => {
        //   const prop = item[`${value}`] ? item[`${value}`] : item.uploaded_on;
        //   if (item[`${value}`]) {
        //         return item[`${value}`];
        //       } else {
        //         return item.uploaded_on;
        //       }
        // });
      }

      // if (order === 'desc') {
      //   result.reverse();
      // }
      return result;
    }
  },

  data() {
    return {
      isReverse: false,
      sortedBy: {},
      sortByItems: [
        { text: 'sort.date.asc', value: 'published_at.asc', asc: true },
        { text: 'sort.date.desc', value: 'published_at.desc', asc: false }
      ]
    };
  },

  mounted() {
    this.sortedBy = this.sortByItems[0];

    const value = get(this.sortedBy, 'value', '').split('.')[0];
    const order = get(this.sortedBy, 'value', '').split('.')[1];

    console.log(value, order);
  },

  methods: {
    getAssetUrl,

    getRelationNames,

    getIconByType(type) {
      let result = 'mdi-file-outline';
      if (type.includes('pdf')) {
        result = 'mdi-file-pdf-outline';
      } else if (type.includes('msword')) {
        result = 'mdi-file-word-outline';
      } else if (type.includes('msexcel')) {
        result = 'mdi-file-excel-outline';
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped></style>
