<template>
  <v-simple-table fixed-header :height="height" class="table table__documents-overview">
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 3rem;">&nbsp;</th>
          <th style="min-width: 7rem;" v-if="isColumnVisible('title')">
            {{ $t('Title') }}
            <v-btn
              icon
              x-small
              :title="$t('changeSortOrder')"
              :class="getSortBtnClass(['title'], currentSort)"
              @click="onClickSort('title')"
            >
              <v-icon :class="getSortIconClass(['title'], currentSort)">mdi-arrow-down</v-icon>
            </v-btn>
          </th>
          <th style="min-width: 8rem;" v-if="isColumnVisible('revision')">{{ $t('Revision') }}</th>
          <th style="min-width: 8rem;" v-if="isColumnVisible('date')">
            {{ $t('Date') }}

            <v-btn
              icon
              x-small
              :title="$t('changeSortOrder')"
              :class="getSortBtnClass(['published_at'], currentSort)"
              @click="onClickSort('published_at')"
            >
              <v-icon :class="getSortIconClass(['published_at'], currentSort)">mdi-arrow-down</v-icon>
            </v-btn>
          </th>
          <th v-if="isColumnVisible('tags')">
            {{ $t('Tags') }}
          </th>
          <th style="min-width: 8rem;" v-if="isColumnVisible('company')">{{ $t('Company') }}</th>
          <th style="min-width: 14rem;" v-if="isColumnVisible('authorities')">{{ $t('Authorities') }}</th>
          <th v-if="isColumnVisible('maintenanceTypes')">{{ $t('MaintenanceTypes') }}</th>
        </tr>
      </thead>
      <tbody>
        <!-- No data -->
        <tr v-if="!items.length">
          <th colspan="7">
            <v-alert text dense type="info" class="mt-4">
              {{ $t('hint.noData') }}
            </v-alert>
          </th>
        </tr>

        <!-- All Items -->
        <tr v-for="(item, i) in sortedItems" :key="item.id" :ref="`row-${i}`">
          <td>
            <v-btn
              icon
              :ripple="false"
              @click="toggleExpandRow(item, i, 'TableManualsDocumentTree')"
              :ref="`btn-collapse-${i}`"
              :data-ref="`btn-collapse-${i}`"
              v-if="item.children.length"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </td>
          <td v-if="isColumnVisible('title')">
            <a
              :href="getAssetUrl(item.id) + '?access_token=' + authToken"
              class="file-item__link d-flex flex-column align-items-center py-2"
              target="_blank"
              :title="$t('text.clickToOpenFile')"
            >
              <div class="d-flex">
                {{ item.title }}
              </div>
            </a>
          </td>
          <td v-if="isColumnVisible('revision')">
            {{ item.revision }}
          </td>
          <td v-if="isColumnVisible('date')">
            {{ $d(new Date(item.published_at), 'short') }}
          </td>
          <td v-if="isColumnVisible('tags')">
            <v-tooltip top v-for="tag in getRelationNames(item, 'base_tags', 'base_tags_id', true)" :key="tag.id">
              <template v-slot:activator="{ on, attrs }">
                <v-chip :ripple="false" small color="light-blue lighten-5" class="ma-1" v-bind="attrs" v-on="on">
                  <span v-if="tag.abbr">{{ tag.abbr }}</span>
                  <span v-else>{{ tag.name }}</span>
                </v-chip>
              </template>
              <span>
                {{ tag.name }}
              </span>
            </v-tooltip>
          </td>
          <td v-if="isColumnVisible('company')">
            <div v-if="item.companies">
              <v-chip
                v-for="company in getRelationNames(item, 'companies', 'companies_id')"
                :key="company.id"
                :ripple="false"
                small
                color="blue lighten-4"
                class="ma-1"
              >
                {{ company.name }}
              </v-chip>
            </div>
          </td>
          <td v-if="isColumnVisible('authorities')">
            <div v-if="item.authorities">
              <v-chip
                v-for="authority in getRelationNames(item, 'authorities', 'authorities_id')"
                :key="authority.id"
                :ripple="false"
                small
                color="accent"
                outlined
                class="ma-1"
              >
                {{ authority.name }}
              </v-chip>
            </div>
          </td>
          <td v-if="isColumnVisible('maintenanceTypes')">
            <v-tooltip top v-for="type in getRelationNames(item, 'bse_mnt_type', 'bse_mnt_types_id', true)" :key="type.id">
              <template v-slot:activator="{ on, attrs }">
                <v-chip :ripple="false" small color="lime lighten-3" class="ma-1" v-bind="attrs" v-on="on">
                  {{ type.abbr }}
                </v-chip>
              </template>
              <span>
                {{ type.name }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { get, sortBy } from 'lodash';
import { getAssetUrl, getRelationNames, getSortBtnClass, getSortIconClass } from '@/services/utils';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import tableMixin from '@/shared/mixins/table';

export default {
  name: 'TableDocuments',

  mixins: [tableMixin],

  props: {
    columns: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },

    height: {
      type: String,
      required: false,
      default: ''
    },

    items: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: `${USER_MODULE}/currentUser`
    }),

    authToken() {
      return localStorage.getItem('auth_token') || '';
    },

    columnValues() {
      const result = [];
      this.columns.forEach(column => {
        const { value } = column;
        if (value) {
          result.push(value);
        }
      });
      return result;
    },

    sortedItems() {
      let result = this.items.slice();
      const prop = get(this.currentSort, 'prop');
      const sortOrder = get(this.currentSort, 'value');
      if (this.items.length && prop && sortOrder) {
        result = sortBy(this.items, item => {
          return item[prop];
        });
      }

      if (sortOrder === 'desc') {
        result.reverse();
      }
      return result;
    }
  },

  data() {
    return {
      currentSort: {
        text: 'sort.title.asc',
        value: 'asc',
        prop: 'title'
      }
    };
  },

  methods: {
    get,

    getAssetUrl,

    getRelationNames,

    getSortBtnClass,

    getSortIconClass,

    isColumnVisible(value) {
      return this.columnValues.includes(value);
    },

    onClickSortItem(item) {
      this.currentSort = Object.assign({}, item);
    },

    onClickSort(propName) {
      const currentDirection = get(this.currentSort, 'value', 'asc');
      const direction = currentDirection === 'asc' ? 'desc' : 'asc';
      this.currentSort = {
        text: `sort.${direction}`,
        value: `${direction}`,
        prop: `${propName}`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.file-item__link {
  &:hover {
    text-decoration: underline;
  }
}
</style>
